import React, { Component } from "react";

import Title from "../components/title";
import Intro from "../components/intro";
import Course from "../components/course";
import Team from "../components/team";
import Register from "../components/register";
import Partners from "../components/partners";
import Patrons from "../components/patrons";

class Home extends Component {
  render() {
    return (
      <div>
        <Title />
        <Team />
        {/* <Intro /> */}
        <Course />
        <Register />
        {/* <Patrons /> */}
        <Partners />
      </div>
    );
  }
}

export default Home;
