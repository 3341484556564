import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Spinner from "react-bootstrap/Spinner";
import axiosInstance from "../common/axios";
import CourseDate from "./courseDate";
import Program from "./program";
import Where from "../components/where";
import Certificate from "../components/certificate";
import CoursePrice from "./coursePrice";
import Teachers from "./teachers";

class Course extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      items: [],
      title: "",
      content: "",
      modalShow: false,
    };
  }
  fetchCourse = () => {
    axiosInstance.get("course").then(
      (result) => {
        this.setState({
          isLoaded: true,
          items: result.data,
        });
      },
      (error) => {
        this.setState({
          error,
        });
      }
    );
  };

  componentDidMount() {
    this.fetchCourse();
  }

  render() {
    const { items, isLoaded } = this.state;
    return (
      <div className="course">
        <Container>
          <h2>Zaplanowane wydarzenia</h2>
          {!isLoaded && <Spinner animation="border" />}

          {items.map((item) => (
            <div key={item.id}>
              <div className="program-name">
                <h1>{item.programName}</h1>
              </div>
              <CourseDate
                date={item.date}
                dateEnd={item.dateEnd}
                dateDescription={item.dateDescription}
                dateVisible={item.dateVisible}
              ></CourseDate>
              <Teachers />
              <Program
                program={item.program}
                programName={item.programName}
              ></Program>
              <Certificate />
              <CoursePrice priceDescription={item.priceDescription} />
              <Where
                description={item.description}
                details={item.details}
                address={item.address}
              ></Where>
            </div>
          ))}
        </Container>
      </div>
    );
  }
}

export default Course;
