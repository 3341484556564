import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import ModalWrapper from "./modal-wrapper.js";

const ewelinaDescription =
  '<img src="img/ewelina-litwinska-korcz.jpg" alt="dr n. med. Ewelina Litwińska-Korcz"/>Absolwentka Uniwersytetu Medycznego w Łodzi. Doktor Ewelina Litwińska-Korcz jest specjalistą w zakresie położnictwa i ginekologii. W 2015 roku uzyskała tytuł doktora nauk medycznych na podstawie badań z zakresu predykcji stanu przedrzucawkowego. Od 2011 roku związana z Instytutem Centrum Zdrowia Matki Polki gdzie zrealizowała szkolenie specjalizacyjne. Od 2021 asystent w I Katedrze i Klinice Warszawskiego Uniwersytetu Medycznego. Odbyła 2-letni staż w zakresie medycyny matczyno-płodowej w londyńskim King’s College Hospital uzyskując prestiżowy certyfikat Diploma in Fetal Medicine potwierdzający szeroką wiedzę w zakresie diagnostyki i terapii płodu. Doktor Ewelina Litwińska-Korcz odbyła również staż w dziedzinie echokardiografii płodu, szkoląc się pod okiem konsultantów z Harris Birthright Research Centre oraz Evelina London Children\'s Hospital. Egzaminator Fetal Medicine Foundation w zakresie badania ultrasonograficznego w I i II trymestrze ciąży. Wykładowca Polskiego Towarzystwa Ginekologów i Położników w zakresie ultrasonografii położniczej. Prowadzi szkolenia praktyczne w zakresie ultrasonografii położniczej w Polsce i na świecie. Autorka licznych publikacji na temat ciąży bliźniaczej, stanu przedrzucawkowego oraz terapii płodu. Posiada certyfikaty Fetal Medicine Foundation ultrasonografii ciąży I, II, III trymestru, echokardiografii płodu, badań dopplerowskich i oceny szyjki macicy w ciąży.';

const magdaDescription =
  '<img src="img/magda-litwinska.jpg" alt="dr n. med. Magdalena Litwińska"/>Absolwentka Uniwersytetu Medycznego w Łodzi oraz Friedrich-Aleksander-Universitat Erlangen-Norymberga (Niemcy). Doktor Magdalena Litwińska jest specjalistą w zakresie położnictwa i ginekologii. W 2018 roku uzyskała tytuł doktora nauk medycznych na podstawie badań z zakresu terapii płodu. Od 2012 roku związana z Instytutem Centrum Zdrowia Matki Polki gdzie zrealizowała szkolenie specjalizacyjne. Od 2021 asystent w I Katedrze i Klinice Warszawskiego Uniwersytetu Medycznego. Odbyła 2-letni staż w zakresie medycyny matczyno-płodowej w londyńskim King’s College Hospital uzyskując prestiżowy certyfikat Diploma in Fetal Medicine potwierdzający szeroką wiedzę w zakresie diagnostyki i terapii płodu. Doktor Magdalena Litwińska odbyła również staż w dziedzinie echokardiografii płodu, szkoląc się pod okiem konsultantów z Harris Birthright Research Centre oraz Evelina London Children\'s Hospital. Wielokrotnie prezentowała wyniki brytyjskich badań na międzynarodowych kongresach. Wykładowca Polskiego Towarzystwa Ginekologów i Położników w zakresie ultrasonografii położniczej oraz predykcji stanu przedrzucawkowego. Prowadzi szkolenia praktyczne w zakresie ultrasonografii położniczej w Polsce i na świecie. Autorka licznych publikacji na temat przewidywania stanu przedrzucawkowego oraz terapii płodu. Posiada certyfikaty Fetal Medicine Foundation ultrasonografii ciąży I, II, III trymestru, echokardiografii płodu, badań dopplerowskich i oceny szyjki macicy w ciąży.';

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      title: "",
      content: "",
      modalShow: false,
    };
  }

  setModalShow = (show) => {
    this.setState({ modalShow: show });
  };

  displayAuthorDescription = (title, content) => {
    this.setState({ title: title, content: content });
    this.setModalShow(true);
  };

  render() {
    return (
      <div className="team">
        <Container>
          <h2>Organizatorzy</h2>
          <Row>
            {/* <Col xs="12" sm="4" md="4">
              <div className="item">
                <Card>
                  <Row>
                    <Col xs="4" sm="12">
                      <Image src="./Img/kypros-nicolaides.jpg"></Image>
                    </Col>
                    <Col xs="8" sm="12">
                      <Card.Body>
                        <Card.Title>prof. Kypros Nicolaides</Card.Title>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col> */}
            <Col xs="12" md="6">
              <div className="item">
                <Card
                  onClick={() =>
                    this.displayAuthorDescription(
                      "dr hab.n.med. Ewelina Litwińska-Korcz",
                      ewelinaDescription
                    )
                  }
                >
                  <Row>
                    <Col xs="4" md="6">
                      <Image src="./Img/ewelina-litwinska-korcz.jpg"></Image>
                    </Col>
                    <Col xs="8" md="6">
                      <Card.Body>
                        <Card.Title>
                          dr hab.n.med. Ewelina Litwińska-Korcz
                        </Card.Title>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
            <Col xs="12" md="6">
              <Card
                onClick={() =>
                  this.displayAuthorDescription(
                    "dr hab.n.med. Magdalena Litwińska",
                    magdaDescription
                  )
                }
              >
                <Row>
                  <Col xs="4" md="6">
                    <Image src="./Img/magda-litwinska.jpg"></Image>
                  </Col>
                  <Col xs="8" md="6">
                    <Card.Body>
                      <Card.Title>dr hab.n.med. Magdalena Litwińska</Card.Title>
                    </Card.Body>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          {/* <h2>Gość specjalny</h2>
          <Row className="justify-content-md-center">
            <Col xs="12" md="6">
              <div className="item">
                <Card>
                  <Row>
                    <Col xs="4" md="6">
                      <Image src="./Img/kypros-nicolaides.jpg"></Image>
                    </Col>
                    <Col xs="8" md="6">
                      <Card.Body>
                        <Card.Title>prof. Kypros Nicolaides</Card.Title>
                      </Card.Body>
                    </Col>
                  </Row>
                </Card>
              </div>
            </Col>
          </Row> */}
        </Container>
        <ModalWrapper
          show={this.state.modalShow}
          onHide={() => this.setModalShow(false)}
          title={this.state.title}
          content={this.state.content}
        />
      </div>
    );
  }
}

export default Team;
