import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

const Teachers = () => {
  return (
    <div className="teachers">
      <h2>Wykładowcy</h2>
      <Container>
        <Row>
          {/* <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Prof. dr hab. n. med. Piotr Sieroszewski</Card.Body>
            </Card>
          </Col> */}
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr hab. n. med. Ewelina Litwińska-Korcz</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr hab. n. med. Magdalena Litwińska</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr Tomasz Szajner</Card.Body>
            </Card>
          </Col>
          {/* <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr hab. n. med. Monika Szpotańska </Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr n. med. Magdalena Rybak-Krzyszkowska</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Prof. dr hab. n. med. Artur Ludwin</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Prof. dr hab. n. med. Dariusz Borowski</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr hab. n. med. prof. ICZMP Piotr Kaczmarek</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr n. med. Agata Majewska</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr hab. n. med. Paweł Gutaj</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr Marisa Borenstein</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Prof. dr hab.n.med. Maciej Borowiec</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Prof. dr hab.n.med. Nadia Sawicka-Gutaj</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr hab.n.med. Anna Szymańska</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>Dr n.med. Emilia Połczyńska-Kaniak</Card.Body>
            </Card>
          </Col>
          <Col className="item" sm="6" md="4">
            <Card>
              <Card.Body>
                <b>Gość specjalny:</b> Prof. Kypros Nicolaides
              </Card.Body>
            </Card>
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default Teachers;
