import React, { Component } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import ImageGallery from "react-image-gallery";

const images = [
  {
    original: "/Img/Slides/slide1.jpg",
  },
  {
    original: "/Img/Slides/slide2.jpg",
  },
  {
    original: "/Img/Slides/slide3.jpg",
  },
];

class Title extends Component {
  render() {
    return (
      <div className="title">
        <Container>
          {/* <Row className="justify-content-md-center">
            <h1>Konferencja Medycyny Płodowej</h1>
          </Row> */}
          <Row>
            <div className="image">
              <ImageGallery
                autoPlay="false"
                showBullets="true"
                showPlayButton="false"
                slideInterval="5000"
                items={images}
              />
            </div>
          </Row>
          {/* <Row className="justify-content-md-center">
            <h3>Hotel Verte, Warszawa 17-18 maj 2024</h3>
          </Row> */}
        </Container>
      </div>
    );
  }
}

export default Title;
